.header, .left-container, .middle-container, .right-container {
    display: flex;
    flex-grow: 1;
}

.header {
    background: darkolivegreen;
    align-items: center;
    padding: 10px;
}

.left-container {
    justify-content: flex-start;
}

.middle-container {
    justify-content: center;
}

.right-container {
    justify-content: flex-end;
}