.title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title>p {
    color: gray;
    text-transform: uppercase;
    font-size: x-small;
    letter-spacing: 3px;
}
.title>h1 {
    font-size: xxx-large;
    color: darkolivegreen;
}
.title>a {
    color: darkolivegreen;
    font-size: x-small;
}
.footer {
    font-size: x-small;
    position:absolute;
    bottom:0;
    width:100%;
    height:60px;
    text-align: center;
    color: gray;
}